var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex align-items-center mb-4"},[_c('ba-base-icon',{staticClass:"text-warning mr-2",attrs:{"name":"ba-right","height":"10px","width":"10px"}}),_c('div',{staticClass:"text-black font-weight-normal"},[_vm._v(_vm._s(_vm.title))])],1),_c('b-tabs',{attrs:{"pills":"","vertical":"","nav-wrapper-class":"w-100"}},_vm._l((_vm.tabs),function(tab,ix){return _c('b-tab',{key:ix,staticClass:"bg-transparent",attrs:{"active":_vm.activeTab === ix,"title":tab.title,"title-link-class":[
                {
                    'bg-blue-100 text-blue-900': _vm.activeTab === ix && !_vm.tabs[ix].isComplete,
                    'bg-transparent text-gray-800': _vm.activeTab !== ix && !_vm.tabs[ix].isComplete,
                    'bg-success-10 text-success-80': _vm.tabs[ix].isComplete,
                } ]},on:{"click":function($event){_vm.activeTab = ix}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{class:{
                            'text-success-80': _vm.tabs[ix].isComplete,
                            'text-gray-800': _vm.activeTab !== ix && !_vm.tabs[ix].isComplete,
                            'text-blue-900': _vm.activeTab === ix && !_vm.tabs[ix].isComplete,
                        }},[_vm._v(" "+_vm._s(tab.title)+" ")]),(_vm.activeTab === ix || _vm.tabs[ix].isComplete)?_c('div',[_c('ba-base-icon',{class:{
                                'text-success-80': _vm.tabs[ix].isComplete,
                                'text-gray-800': _vm.activeTab !== ix && !_vm.tabs[ix].isComplete,
                                'text-blue-900': _vm.activeTab === ix && !_vm.tabs[ix].isComplete,
                            },attrs:{"name":_vm.tabs[ix].isComplete ? 'ba-check' : 'ba-arrow-right'}})],1):_vm._e()])]},proxy:true}],null,true)})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }