









import Vue from "vue";

export default Vue.extend({
    name: "BaseRadio",
    props: {
        id: { type: String, required: true },
        value: { type: String, required: true },
        name: { type: String, required: true },
        label: { type: String, required: false, default: "" },
        optionValue: { type: String, required: true },
        disabled: { type: Boolean, required: false, default: false },
    },
    computed: {
        shouldBeSelected(): boolean {
            return this.value === this.optionValue;
        },
    },
});
