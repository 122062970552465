








import Vue from "vue";

export default Vue.extend({
    name: "BaseCheckbox",
    props: {
        id: { type: String, required: true },
        value: { type: Boolean, default: false },
        label: { type: String, required: false, default: "" },
        optionValue: { type: Boolean, required: false, default: false },
        disabled: { type: Boolean, required: false, default: false },
    },
    computed: {
        model: {
            get(): any {
                return this.value;
            },
            set(value: any): any {
                this.$emit("input", value);
            },
        },
    },
});
