








import Vue from "vue";

export default Vue.extend({
    props: {
        icon: { type: String, required: false, default: "" },
        type: { type: String, required: false, default: "button" },
        text: { type: String, required: false, default: "" },
        variant: { type: String, required: false, default: "primary" },
        size: { type: String, required: false, default: "default" },
        disabled: { type: Boolean, required: false, default: false },
        loading: { type: Boolean, required: false, default: false },
    },
    computed: {
        buttonClasses(): string[] {
            return ["variant", "size"].map((item) => {
                if (item === "variant") return `base-button--${this.variant}`;
                if (item === "size") return `base-button--${this.size}`;
                return "";
            });
        },
    },
});
